import * as VP from "@opr-finance/api-definitions";

import { IncreaseLimitApplication } from "./types";

export function mapApplicationToLfpRequest(
    data: IncreaseLimitApplication
): VP.components["schemas"]["ApplicationV8PostRequest"] {
    const application: VP.components["schemas"]["ApplicationV8PostRequest"] = {
        appliedProductId: process.env.REACT_APP_PRODUCT_ID as string,
        applicationChannel: "WEB",
        applicationCategory: "TOP_UP",
        smeId: data.smeId,
        notificationEmail: data.email,
        notificationMobileNumber: data.phone,
        desiredAmount: parseInt(data.newCreditLimit.replace(/\s/g, "")),
        extras: {
            turnover: data.monthlyIncomeGross,
            creditCheck: data.allowInspection ? "yes" : "no",
            consentGiven: data.personalInfoConsent ? "yes" : "no",
            checkPEPlistConsent: data.checkPEPlistConsent ? "yes" : "no",
            campaignCode: data.campaignCode,
        },
    };

    return application;
}
