import React, { useState } from "react";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";

import { StyledGrid } from "@opr-finance/component-grid";
import { StyledPageTitle } from "@opr-finance/component-content";
import { TopupPageStyles, ButtonStyles, ModalStyles } from "@opr-finance/theme-flex-online";
import { Font } from "@opr-finance/component-fonts";
import {
    TextField,
    SelectField,
    CheckboxField,
    useForm,
    getOption,
    option,
    SelectOption,
} from "@opr-finance/component-forms";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";
import { FormattedStatement, T_FeatureInvoicesState } from "@opr-finance/feature-statements";
import { pickOff, isNumber, formatOrgNumFin, checkTopupEligibility } from "@opr-finance/utils";
import { Icon } from "@opr-finance/component-icon";
import { StyledButton } from "@opr-finance/component-button";
import { StyledLink } from "@opr-finance/component-link-to";
import { Modal } from "@opr-finance/component-modal-dialog";
import { E_AllowedAccountStates } from "@opr-finance/feature-account";

import {
    TopupPageProps,
    IncreaseCreditLimitFormData,
    IncreaseLimitApplication,
    StatementsStatus,
} from "./types";
import { AppState, E_Routes } from "../../types/general";
import { sendTopupApplication } from "../../api/topupApplication";
import { messages } from "./messages";
import { NotEligiblePage } from "../../components/NotEligblePage/NotEligiblePage";
import { AppInitializer } from "@opr-finance/feature-initializer";
import { AppActionConstants } from "../../actions/actions";
import { AppLoader } from "@opr-finance/feature-loader";
import { mapApplicationToLfpRequest } from "./mapApplicationToLfpRequest";
import { topupRules } from "../../constants/rules";
import { automateESigning, mapToAutomateESigningPayload } from "../../utils/automateESigning";
import { Loader } from "@opr-finance/component-loader";

const isRescoringAllowed = process.env.REACT_APP_IS_RESCORING_ALLOWED === "1" ? true : false;

export function TopupPage(props: TopupPageProps) {
    const { formatMessage: fm } = useIntl();
    const history = useHistory();

    const authenticated = useSelector((state: AppState) => state.session.authenticated);
    const accountState = useSelector((state: AppState) => state.account.accountState);

    const account = useSelector((state: AppState) => {
        return state.account.account;
    });

    const invoice = useSelector((state: AppState) => {
        return state.invoices.formatted[0];
    });
    const allInvoices = useSelector((state: AppState) => {
        return state.invoices.formatted;
    });

    const { info, boardmembers } = useSelector((state: AppState) => {
        return state.customer.companyInfo;
    });

    const [isSending, setIsSending] = useState<boolean>(false);
    const [applicationSent, setApplicationSent] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [modalOpen, toggleModalOpen] = useState(false);

    const boardMember = boardmembers && boardmembers[0];

    const disbursementAccount = account?.disbursementAccount?.externalAccountNumber;

    const [application, setApplication] = useState<IncreaseCreditLimitFormData>({
        newCreditLimit: "",
        allowInspection: false,
        personalInfoConsent: false,
        checkPEPlistConsent: false,
        monthlyIncomeGross: "",
        campaignCode: "",
    });

    const orgNumber = info?.organizationNumber ? formatOrgNumFin(info?.organizationNumber) : "";

    const creditLimit = account?.creditLimit ? account.creditLimit : 0;
    const blockedStatus = account?.blockedStatus ? account.blockedStatus : false;

    async function handleForm() {
        const data: IncreaseLimitApplication = {
            givenName: `${boardMember?.givenName} || ""`,
            surName: `${boardMember?.surname} || ""`,
            personalID: `${boardMember?.reference} || ""`,
            email: `${boardMember?.email || info?.email || ""}`,
            phone: `${boardMember?.phone || info?.phone || ""}`,
            bankAccount: `${disbursementAccount} || ""`,
            smeId: `${info?.id} || ""`,
            ...application,
        };

        setIsSending(true);
        try {
            const mappedApplication = mapApplicationToLfpRequest(data);
            const result = await sendTopupApplication(mappedApplication);

            if (result.status === (200 | 201)) {
                const applicationResponse = await result.json();

                const automateESigningPayload = mapToAutomateESigningPayload(applicationResponse, {
                    organizationNumber: info?.organizationNumber,
                    companyName: info?.companyName,
                });

                const automateEsignResponse = await automateESigning(automateESigningPayload);

                setApplicationSent(true);
            } else {
                history.push(E_Routes.ERROR);
            }
        } catch (error) {
            history.push(E_Routes.ERROR);
        } finally {
            setIsSending(false);
        }
    }

    let formattedCurrency = (value) => {
        return currency({
            value: value,
            minimumFractionDigits: 0,
            locale: CurrencyLocale.Finland,
            currency: CurrencyFormat.Finland,
        });
    };

    const sums: number[] = [5000, 8000, 10000, 15000, 20000];
    const validSums: SelectOption[] = sums
        .filter((sum) => {
            return sum > creditLimit && sum - creditLimit <= 5000;
        })
        .map((sum) => {
            return option(
                currency({
                    value: sum,
                    minimumFractionDigits: 0,
                    locale: CurrencyLocale.Finland,
                    currency: CurrencyFormat.Finland,
                })
            );
        });
    const amounts: SelectOption[] = [option(fm(messages.dropdownChoose)), ...validSums];

    const validAmounts: string[] = pickOff(
        amounts,
        (option: SelectOption) => option.value === fm(messages.dropdownChoose)
    ).map((option: SelectOption) => {
        return option.value;
    });

    const schema = yup.object().shape({
        newCreditLimit: yup.string().oneOf(validAmounts, fm(messages.alertTextRequired)),
        monthlyIncomeGross: yup.string().required(fm(messages.alertTextRequired)),
        campaignCode: yup.string().notRequired(),
        allowInspection: yup
            .boolean()
            .oneOf([true], fm(messages.alertTextRequired))
            .required(fm(messages.alertTextRequired)),
        personalInfoConsent: yup
            .boolean()
            .oneOf([true], fm(messages.alertTextRequired))
            .required(fm(messages.alertTextRequired)),
        checkPEPlistConsent: yup
            .boolean()
            .oneOf([true], fm(messages.alertTextRequired))
            .required(fm(messages.alertTextRequired)),
    });

    const { form, processChange, processFocus, processBlur, Error, processReset } =
        useForm<IncreaseCreditLimitFormData>({
            initial: {
                newCreditLimit: "Valitse",
                allowInspection: false,
                personalInfoConsent: false,
                checkPEPlistConsent: false,
                monthlyIncomeGross: "",
                campaignCode: "",
            },
            schema,
            onChange: (data) => {
                setApplication(data);
                setIsValid(true);
            },
            onError: (data) => {
                setIsValid(false);
            },
            onBlur: () => {},
            onFocus: () => {},
            styleConfig: {
                formError: TopupPageStyles.formError(),
            },
        });

    const checkStatements = (statements: Array<FormattedStatement>): StatementsStatus => {
        let countableStatements = statements
            .slice(0, 4)
            .filter((statement) => statement?.status === "PAIDINFULL");

        if (countableStatements.length > 3) countableStatements = countableStatements.slice(0, 3);
        const overdueDays = countableStatements[0].overdueDays || 0;

        return {
            overdueDays,
            statementsCount: countableStatements.length,
        };
    };

    const paidInvoices = allInvoices.filter((invoice) => invoice.status === "PAIDINFULL");

    const accountData = {
        creditLimit,
        accountState: account?.state,
        overdueDays: invoice && invoice.overdueDays,
        paidInvoicesCount: paidInvoices.length,
        blockedStatus,
    };

    const isCustomerEligible = isRescoringAllowed && checkTopupEligibility(accountData, topupRules);

    if (!authenticated || accountState === E_AllowedAccountStates.COLLECTION) {
        return <Redirect to={E_Routes.ROOT} />;
    }

    return (
        <StyledGrid styleConfig={{ root: TopupPageStyles.topupPageRootStyles() }}>
            {isCustomerEligible ? (
                <StyledGrid styleConfig={{ root: TopupPageStyles.topupPageRootStyles() }}>
                    <StyledPageTitle
                        title={fm(messages.pageTitle)}
                        styleConfig={{
                            pageTitleContainer: props.styleConfig.titleBox,
                            pageTitleText: props.styleConfig.pageTitle,
                        }}
                    />
                    <StyledGrid styleConfig={{ root: TopupPageStyles.applicationContainer() }}>
                        <Font styleConfig={{ root: TopupPageStyles.formTitle() }}>
                            {fm(messages.heading)}
                        </Font>
                        <Font styleConfig={{ root: TopupPageStyles.formDescription() }}>
                            {fm(messages.topTextInfoLongText)}
                        </Font>
                        <Font styleConfig={{ root: TopupPageStyles.formDescription() }}>
                            {fm(messages.topTextInfo2)}
                        </Font>

                        <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                            <Font styleConfig={{ root: TopupPageStyles.sectionTitle() }}>
                                {fm(messages.Subheading1)}
                            </Font>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.textCreditLimit)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {formattedCurrency(creditLimit)}
                                </Font>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.textNewCredit)}
                                </Font>
                                <StyledGrid
                                    styleConfig={{ root: TopupPageStyles.inputContainer() }}>
                                    <SelectField
                                        name="newCreditLimit"
                                        value={getOption(amounts, form.data.newCreditLimit)}
                                        icon={<Icon icon={["fa", "caret-down"]} size="lg" />}
                                        inputConfig={{
                                            select: {
                                                value: form.data.newCreditLimit,
                                                onChange: (value) => {
                                                    processChange({
                                                        field: "newCreditLimit",
                                                        value: value?.value,
                                                        validate: true,
                                                        touched: true,
                                                    });
                                                },
                                            },
                                        }}
                                        options={amounts}
                                        styleConfig={{
                                            select: TopupPageStyles.select(),
                                            singleValue: TopupPageStyles.singleValue(),
                                            option: TopupPageStyles.singleOption(),
                                            menu: TopupPageStyles.menu(),
                                        }}
                                    />
                                    <Error field="newCreditLimit" />
                                </StyledGrid>
                            </StyledGrid>
                        </StyledGrid>

                        <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                            <Font styleConfig={{ root: TopupPageStyles.sectionTitle() }}>
                                {fm(messages.Subheading2)}
                            </Font>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.companyName)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {info?.companyName}
                                </Font>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.businessID)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {orgNumber}
                                </Font>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.monhtlySale)}
                                </Font>
                                <StyledGrid
                                    styleConfig={{ root: TopupPageStyles.inputContainer() }}>
                                    <TextField
                                        inputConfig={{
                                            name: "monthlyIncomeGross",
                                            placeholder: fm(messages.placeholder),
                                            value: form.data.monthlyIncomeGross,
                                            onFocus: () => {
                                                processFocus("monthlyIncomeGross");
                                            },
                                            onChange: (e) => {
                                                if (isNumber(e.target.value)) {
                                                    processChange({
                                                        field: "monthlyIncomeGross",
                                                        value: e.target.value,
                                                    });
                                                }
                                            },
                                            onBlur: () => {
                                                processBlur("monthlyIncomeGross");
                                            },
                                        }}
                                        styleConfig={{ root: TopupPageStyles.textField() }}
                                    />
                                    <Error field="monthlyIncomeGross" />
                                </StyledGrid>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.emailText)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {info?.email}
                                </Font>
                            </StyledGrid>
                        </StyledGrid>

                        {/* Takaajan tiedot */}
                        <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                            <Font styleConfig={{ root: TopupPageStyles.sectionTitle() }}>
                                {fm(messages.Subheading3)}
                            </Font>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.guaranteeName)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {boardMember?.givenName + " " + boardMember?.surname}
                                </Font>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.guaranteeSSN)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {boardMember?.reference}
                                </Font>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.guaranteePhone)}
                                </Font>
                                <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                    {info?.phone}
                                </Font>
                            </StyledGrid>
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formItemContainer() }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                    {fm(messages.campaignCode)}
                                </Font>
                                <StyledGrid
                                    styleConfig={{ root: TopupPageStyles.inputContainer() }}>
                                    <TextField
                                        inputConfig={{
                                            name: "campaignCode",
                                            placeholder: fm(messages.placeholder),
                                            value: form.data.campaignCode,
                                            onFocus: () => {
                                                processFocus("campaignCode");
                                            },
                                            onChange: (e) => {
                                                processChange({
                                                    field: "campaignCode",
                                                    value: e.target.value,
                                                });
                                            },
                                            onBlur: () => {
                                                processBlur("campaignCode");
                                            },
                                        }}
                                        styleConfig={{ root: TopupPageStyles.textField() }}
                                    />
                                </StyledGrid>
                            </StyledGrid>
                            <StyledGrid
                                styleConfig={{
                                    root: TopupPageStyles.formItemCheckBoxContainer(),
                                }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabelCheckBox() }}>
                                    {fm(messages.checkboxInfo1)}
                                </Font>
                                <StyledGrid
                                    styleConfig={{
                                        root: TopupPageStyles.inputContainerCheckbox(),
                                    }}>
                                    <CheckboxField
                                        checked={form.data.allowInspection}
                                        checkedIcon={
                                            <Icon
                                                icon={["fa", "check"]}
                                                size="lg"
                                                color="#82c45f"
                                            />
                                        }
                                        onClick={() => {
                                            processChange({
                                                field: "allowInspection",
                                                value: !form.data.allowInspection,
                                                validate: true,
                                                touched: true,
                                            });
                                        }}
                                        styleConfig={{
                                            root: TopupPageStyles.checkbox(),
                                            checked: {},
                                        }}
                                    />
                                    <Error field="allowInspection" />
                                </StyledGrid>
                            </StyledGrid>
                            <StyledGrid
                                styleConfig={{
                                    root: TopupPageStyles.formItemCheckBoxContainer(),
                                }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabelCheckBox() }}>
                                    {fm(messages.checkboxInfo2)}

                                    <StyledLink
                                        styleConfig={{ root: TopupPageStyles.link() }}
                                        target="_blank"
                                        href="https://flex.yritysluotto.fi/yritysluotto-flex/rekisteriseloste/">
                                        {fm(messages.checkboxInfo2LinkText)}
                                    </StyledLink>
                                    {" *"}
                                </Font>
                                <StyledGrid
                                    styleConfig={{
                                        root: TopupPageStyles.inputContainerCheckbox(),
                                    }}>
                                    <CheckboxField
                                        checked={form.data.personalInfoConsent}
                                        checkedIcon={
                                            <Icon
                                                icon={["fa", "check"]}
                                                size="lg"
                                                color="#82c45f"
                                            />
                                        }
                                        onClick={() => {
                                            processChange({
                                                field: "personalInfoConsent",
                                                value: !form.data.personalInfoConsent,
                                                validate: true,
                                                touched: true,
                                            });
                                        }}
                                        styleConfig={{
                                            root: TopupPageStyles.checkbox(),
                                            checked: {},
                                        }}
                                    />
                                    <Error field="personalInfoConsent" />
                                </StyledGrid>
                            </StyledGrid>
                            <StyledGrid
                                styleConfig={{
                                    root: TopupPageStyles.formItemCheckBoxContainer(),
                                }}>
                                <Font styleConfig={{ root: TopupPageStyles.formLabelCheckBox() }}>
                                    {fm(messages.checkboxInfo3)}
                                </Font>
                                <StyledGrid
                                    styleConfig={{
                                        root: TopupPageStyles.inputContainerCheckbox(),
                                    }}>
                                    <CheckboxField
                                        checked={form.data.checkPEPlistConsent}
                                        checkedIcon={
                                            <Icon
                                                icon={["fa", "check"]}
                                                size="lg"
                                                color="#82c45f"
                                            />
                                        }
                                        onClick={() => {
                                            processChange({
                                                field: "checkPEPlistConsent",
                                                value: !form.data.checkPEPlistConsent,
                                                validate: true,
                                                touched: true,
                                            });
                                        }}
                                        styleConfig={{
                                            root: TopupPageStyles.checkbox(),
                                            checked: {},
                                        }}
                                    />
                                    <Error field="checkPEPlistConsent" />
                                </StyledGrid>
                            </StyledGrid>
                        </StyledGrid>

                        <StyledGrid styleConfig={{ root: TopupPageStyles.bottomContainer() }}>
                            {!isValid && form.touched.length > 0 && (
                                <Font styleConfig={{ root: TopupPageStyles.formBottomError() }}>
                                    {fm(messages.alertText)}
                                </Font>
                            )}
                            <StyledButton
                                onClick={() => {
                                    isValid && toggleModalOpen(!modalOpen);
                                }}
                                styleConfig={{
                                    root: !isValid
                                        ? ButtonStyles.disabledButtonStyles({
                                              marginTop: "10px",
                                          })
                                        : ButtonStyles.greenButtonStyles({ marginTop: "10px" }),
                                }}>
                                <Font styleConfig={{ root: ButtonStyles.buttonFontStyles() }}>
                                    {fm(messages.buttontextPreview)}{" "}
                                    <Icon icon={["fa", "angle-double-right"]} />{" "}
                                </Font>
                            </StyledButton>
                        </StyledGrid>
                    </StyledGrid>

                    {/* Modal */}
                    <Modal
                        modalTitle={
                            applicationSent ? fm(messages.sentModalTitle) : fm(messages.pageTitle)
                        }
                        isOpen={modalOpen}
                        onClick={() => {
                            toggleModalOpen(false);
                            setApplicationSent(false);
                            processReset();
                        }}
                        styleConfig={{
                            closeIcon: ModalStyles.modalCloseIcon(),
                            overlay: ModalStyles.modalOverlay(),
                            content: applicationSent
                                ? ModalStyles.modalContentScroll({ height: ["500px", "300px"] })
                                : ModalStyles.modalContentScroll(),
                            title: ModalStyles.modalTitle(),
                            titleText: ModalStyles.titleText(),
                        }}>
                        {applicationSent && (
                            <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                                <Font as="p" styleConfig={{ root: TopupPageStyles.contentText() }}>
                                    {fm(messages.sentModalInfo1)}
                                </Font>
                                <Font as="p" styleConfig={{ root: TopupPageStyles.contentText() }}>
                                    {fm(messages.sentModalInfo2)}
                                    <StyledLink
                                        styleConfig={{ root: TopupPageStyles.link() }}
                                        target="_blank"
                                        href={fm(messages.customerServiceLink)}>
                                        {fm(messages.customerServiceLinkText)}
                                    </StyledLink>{" "}
                                    {fm(messages.sentModalInfo3)}
                                </Font>
                            </StyledGrid>
                        )}
                        {!applicationSent && (
                            <>
                                <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                                    <Font styleConfig={{ root: TopupPageStyles.sectionTitle() }}>
                                        {fm(messages.Subheading1)}
                                    </Font>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {"Nykyinen luottoraja"}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {formattedCurrency(creditLimit)}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {"Uusi luottoraja"}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {form.data.newCreditLimit}
                                        </Font>
                                    </StyledGrid>
                                </StyledGrid>

                                <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                                    <Font styleConfig={{ root: TopupPageStyles.sectionTitle() }}>
                                        {fm(messages.Subheading2)}
                                    </Font>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.companyName)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {info?.companyName}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.businessID)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {info?.organizationNumber}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.monhtlySale)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {formattedCurrency(form.data.monthlyIncomeGross)}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.emailText)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {info?.email}
                                        </Font>
                                    </StyledGrid>
                                </StyledGrid>

                                <StyledGrid styleConfig={{ root: TopupPageStyles.formSection() }}>
                                    <Font styleConfig={{ root: TopupPageStyles.sectionTitle() }}>
                                        {fm(messages.Subheading3)}
                                    </Font>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.guaranteeName)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {boardMember?.givenName + " " + boardMember?.surname}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.guaranteeSSN)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {boardMember?.reference}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.guaranteePhone)}
                                        </Font>
                                        {typeof boardMember?.phone === "string" ? (
                                            <Font
                                                styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                                {boardMember?.phone}
                                            </Font>
                                        ) : (
                                            <Font
                                                styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                                {" "}
                                            </Font>
                                        )}
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemContainer(),
                                        }}>
                                        <Font styleConfig={{ root: TopupPageStyles.formLabel() }}>
                                            {fm(messages.campaignCode)}
                                        </Font>
                                        <Font styleConfig={{ root: TopupPageStyles.userInfo() }}>
                                            {form.data.campaignCode}
                                        </Font>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemCheckBoxContainer(),
                                        }}>
                                        <Font
                                            styleConfig={{
                                                root: TopupPageStyles.formLabelCheckBox(),
                                            }}>
                                            {fm(messages.checkboxInfo1)}
                                        </Font>
                                        <StyledGrid
                                            styleConfig={{
                                                root: TopupPageStyles.inputContainerCheckbox(),
                                            }}>
                                            <Font
                                                styleConfig={{
                                                    root: TopupPageStyles.userInfo(),
                                                }}>
                                                {form.data.allowInspection ? "Kyllä" : "Ei"}
                                            </Font>
                                        </StyledGrid>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemCheckBoxContainer(),
                                        }}>
                                        <Font
                                            styleConfig={{
                                                root: TopupPageStyles.formLabelCheckBox(),
                                            }}>
                                            {fm(messages.checkboxInfo2Modal)}
                                        </Font>
                                        <StyledGrid
                                            styleConfig={{
                                                root: TopupPageStyles.inputContainerCheckbox(),
                                            }}>
                                            <Font
                                                styleConfig={{
                                                    root: TopupPageStyles.userInfo(),
                                                }}>
                                                {form.data.personalInfoConsent ? "Kyllä" : "Ei"}
                                            </Font>
                                        </StyledGrid>
                                    </StyledGrid>
                                    <StyledGrid
                                        styleConfig={{
                                            root: TopupPageStyles.formItemCheckBoxContainer(),
                                        }}>
                                        <Font
                                            styleConfig={{
                                                root: TopupPageStyles.formLabelCheckBox(),
                                            }}>
                                            {fm(messages.checkboxInfo3)}
                                        </Font>
                                        <StyledGrid
                                            styleConfig={{
                                                root: TopupPageStyles.inputContainerCheckbox(),
                                            }}>
                                            <Font
                                                styleConfig={{
                                                    root: TopupPageStyles.userInfo(),
                                                }}>
                                                {form.data.checkPEPlistConsent ? "Kyllä" : "Ei"}
                                            </Font>
                                        </StyledGrid>
                                    </StyledGrid>
                                </StyledGrid>

                                <StyledGrid
                                    styleConfig={{ root: TopupPageStyles.bottomContainer() }}>
                                    {isSending ? (
                                        <StyledGrid
                                            styleConfig={{
                                                root: {
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "20px",
                                                },
                                            }}>
                                            <Loader isLoading={true} />
                                        </StyledGrid>
                                    ) : (
                                        <>
                                            <StyledButton
                                                onClick={() => {
                                                    handleForm();
                                                    // processReset();
                                                    setIsValid(false);
                                                }}
                                                styleConfig={{
                                                    root: ButtonStyles.greenButtonStyles({
                                                        marginTop: "10px",
                                                    }),
                                                }}>
                                                <Font
                                                    styleConfig={{
                                                        root: ButtonStyles.buttonFontStyles(),
                                                    }}>
                                                    {fm(messages.buttonTextSend)}{" "}
                                                    <Icon icon={["fa", "angle-double-right"]} />{" "}
                                                </Font>
                                            </StyledButton>
                                            <StyledButton
                                                onClick={() => {
                                                    toggleModalOpen(false);
                                                }}
                                                styleConfig={{
                                                    root: ButtonStyles.backButtonStyles(),
                                                }}>
                                                <Font
                                                    styleConfig={{
                                                        root: ButtonStyles.backButtonFontStyles(),
                                                    }}>
                                                    <Icon icon={["fa", "angle-double-left"]} />{" "}
                                                    {fm(messages.buttonTextGoback)}
                                                </Font>
                                            </StyledButton>
                                        </>
                                    )}
                                </StyledGrid>
                            </>
                        )}
                    </Modal>
                </StyledGrid>
            ) : (
                <NotEligiblePage />
            )}
        </StyledGrid>
    );
}
